body{
    background-color: #CDCCCC
}

section{   
    @extend .mb-5 ;
    @extend .bg-body; 
}

.setoff {
    padding: 1rem;
    
    @extend .shadow;
    @extend .rounded;
}

section h2{
    @extend .lead;
    @extend .fw-semibold;
}

.btn-success {
    @extend #{'.btn', '.btn-lg', '.btn-success'};    
}

.btn-warning {
    @extend #{'.btn', '.btn-lg', '.btn-warning'};    
}

.btn-primary {
    @extend #{'.btn', '.btn-lg', '.btn-primary'};       
}

.btn-dark {
    @extend #{'.btn', '.btn-lg', '.btn-dark'};       
}

.btn-primary-outline{
    @extend #{'.btn', '.btn-lg', '.btn-outline-primary'};       
}


.productsGrid{
    @extend #{'.d-flex','.flex-wrap','.justify-content-between'};
}

.no-records{
    @extend #{'.fst-italic','.text-muted'};
    text-align: center;
    
}

.flex-title{
    display: flex;
    margin: 1rem 0;
}
.flex-title h2{
    font-size: 1.4rem;
    margin: 0;
    margin-left: 0.5rem;
}

.clear-link a:hover, a:active, a:visited, a:link{
    text-decoration: none;
    
}

.avatar{
    vertical-align: middle;   
    border-radius: 50%;
    object-fit: cover;
    background-color: $white;
}


.page{
    padding: 0.8rem;
    min-height: 100vh;
}

.page .panel {
    margin-top: 1rem;
}

.panel .formGroup:not(:first-child){
    margin-top: 0.8rem;
}

.formGroup:not(:first-child){
    margin-top: 0.8rem;
}

.panel{
    background-color: $white;
    padding:  0.8rem;
    border-radius: 4px;
    box-shadow: 0 3.2px 7.2px 0 $gray-700;
    display: flex;
    flex-direction: column;    
}

.toolbar{
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
    width: 100%;
    gap: 0.5rem;
}

.panel .toolbar { margin: 0;}

.list .list-item {
    padding: 1rem 0;   
}

.list .list-item:not(:last-child) {
    border-bottom: 1px solid $gray-400;
}

.bottom-toolbar{        
    margin: 2rem 0;
    display: flex;    
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
}

.bottom-toolbar button:only-child{
    align-self: flex-end !important;
}

.bottom-toolbar button:first-child{
    align-self: flex-start !important;
}

.bottom-toolbar button:last-child{
    align-self: flex-end !important;
}

.bottom-toolbar button{
    flex-grow: 1;
}

hr{border-bottom: solid 1px #aaa ;  }

p {margin:0}

.smallText {
    font-size: 0.875rem;
}

.notes {
    background-color: #f7eab2;
    color: black;
    font-weight: 300;
    padding: 0.5rem;
    width: 100%;
    text-align: left;
    border-radius: 0.5rem;
    word-wrap: break-word;
    overflow: scroll;
    max-height: 4rem;
}

.quantityAbove{
    background-color: rgba(247, 196, 54, 0.2)!important;
}

.quantityEqual {
    background-color: rgba(92, 219, 46, 0.2)!important;
}

.quantityBelow{    
    background-color: rgba(222, 50, 38, 0.2) !important;
}

.link {
  font-size: 0.8rem;
}

.required {
    font-weight: bold;
}

.required::after{
    content: "*";
    color: red;
}

.background-nube {
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5)), url("../../../public/img/background.svg");
}