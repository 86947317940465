.tabs{
   /* @extend #{'.row'};*/
    margin: 1rem 0;
    padding: 0;
    
   overflow-x: scroll;
  
}

.tabs::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

.tabs ul{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0;
    margin-bottom: 0;
}

.tabs li{
    padding: 0.5rem;
    list-style-type: none;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    line-height: 1;
    text-align: center;
    flex-shrink: 0;
}

.tabs .selected{
    background-color: map-get($theme-colors,"primary");
    color: $white;
    padding: 0 .6rem;
    border-radius: 10rem;
}