.sidebar{
    @extend #{'.d-flex','.flex-column','.flex-shrink-0','.p-3'};
    height: 100%;
    width: 100%; 
    background-color: #fff;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 5rem;
    transition: 0.5s;
}

.sidebar-closebtn{   
    position: absolute;
    top: 0;
    right: 0;    
    margin-left: 150px;
}

.sidebar-menu{
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;}
.sidebar-menu ul{
    padding-left: 0;
}
.sidebar-menu li{
    @extend #{'.list-unstyled','.fw-normal'};

}

.sidebar-submenu{
    background-color: #e3e3e3;
    padding: 0.3rem 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;    
    margin-top: 1.5rem;
}

.sidebar-submenu a {
     @extend .link-dark;    
    line-height: 2.5rem;
    vertical-align: middle;
}

.sidebar-submenu--border-bottom {   
    border-bottom: solid 1px #aaa ;    
}

.sidebar-empresa {
    @extend .sidebar-submenu;
    padding: 1rem;
    flex-direction: row;
}

.sidebar-logoff{
    margin-top: 1rem;
   
}